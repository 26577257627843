'use strict'


import { Dateq }                          from '../../index.mjs';
// import { DeviceMsgsModule } from '../../messages/index.mjs';
import { ElementCommon }                  from '../Element.class.mjs';
import { ElementType }                    from '../ElementType.class.mjs';
import { ConnectionCommon as Connection } from '../Devices/index.mjs';


const _attrs = {
    // _id:   ElementCommon.attrs._id,
    // _name: ElementCommon.attrs._name,
};


// const _typeField = _attrs._type;

const _field = ElementType.TestConnection;


export class TestConnection extends Connection
{
    static get(d, attrs)
    {
        return d
            ? (d instanceof TestConnection
                ? d
                : new TestConnection(d, attrs)
            )
            
            : undefined;
    }

   
    static get attrs()
    {
        return _attrs;
    }
    

    static get typeField()
    {
        return undefined;//_typeField;
    }


    _merge(d, attrsE, attrsI)
    {
        return super._merge(d, attrsE, {..._attrs, ...attrsI});
    }
}