'use strict'


import { Test } from './index.mjs';


const _attrs = {
    _direction:        '_direction',
    _duration:         '_duration',
    _durationInterval: '_durationInterval',
    _sizeReq:          '_sizeReq',
    _sizeTxfr:         '_sizeTxfr',
    _sizeTxfrInterval: '_sizetxfrinterval'
};


export class TestFtp extends Test
{
    constructor(d, attrs)
    {
        super(d, attrs);
    }


    // Factory method
    static get(d, attrs)
    {
        return (
            d
            ? (d instanceof TestFtp
                ? d
                : new TestFtp(d, attrs)
            )
            : undefined
        );
    }


    // Override
    get resultcategory2()
    {
        return Test.resultCategories.Great;
    }


    static get attrs()
    {
        return _attrs;
    }


    get direction()
    {
        return this[_attrs._direction];
    }


    get duration() // secs
    {
        const precision = 2;
        const factor = Math.pow(10, precision);

        return (! Number.isNaN(this.durationI))
            ? Math.round((this.durationI / 1000) * factor) / factor
            : undefined;
    }


    get durationI() // msecs
    {
        return this[_attrs._duration];
    }


    get rate() // Kbps
    {
        const precision = 2;
        const factor = Math.pow(10, precision);

        return (! Number.isNaN(this.rateI))
            ? Math.round((this.rateI / 1024) * factor) / factor
            : undefined;
    }

    
    get rateI() // bps
    {
        return (this.durationI && ! Number.isNaN(this.sizeI) && ! Number.isNaN(this.durationI))
            ? ((this.sizeI * 8) / (this.durationI / 1000))
            : undefined;
    }


    get size() // bytes
    {
        const precision = 2;
        const factor = Math.pow(10, precision);

        return Math.round(this.sizeI * factor) / factor;
        // return Math.round(this.sizeI);
    }


    get sizeI() // bytes
    {
        return this[_attrs._sizeTxfr];
    }


    get sizeRequest()
    {
        return this[_attrs._sizeReq];
    }


    _merge(d, attrs)
    {
        super._merge(d, attrs, attrs ? undefined : _attrs);
    }
}