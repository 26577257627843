'use strict';


// import { DeviceMsgsModule } from '../../messages/index.mjs';
import { ElementCommon }  from '../Element.class.mjs';
import { Utils }          from '../../Utils.mjs';

import { MobileCellType } from './MobileCellType.class.mjs';


const _attrs       = {
    // [TBD]
    _connecteddate:                         '_connecteddate',
    [ElementCommon.attrs._lastupdateddate]: ElementCommon.attrs._lastupdateddate,

    _areacode:                              '_areacode',
    _asu:                                   '_asu',
    _bandwidth:                             '_bandwidth',
    _carrieraggregation:                    '_carrieraggregation',
    _cellid:                                '_cellid',
    [ElementCommon.attrs._date]:            ElementCommon.attrs._date,
    _dlarfcn:                               '_dlarfcn',
    _dlfrequency:                           '_dlfrequency',
    _frequencyband:                         '_frequencyband',
    _frequencybandname:                     '_frequencybandname',
    _frequencynominal:                      '_frequencynominal',
    [ElementCommon.attrs._id]:              ElementCommon.attrs._id,
    _lac:                                   '_lac',
    _localcellid:                           '_localcellid',
    _mcc:                                   '_mcc',
    _mnc:                                   '_mnc',
    _operator:                              '_operator',
    _plmn:                                  '_plmn',
    _rssi:                                  '_rssi',
    _signalcategory:                        '_signalcategory',
    _signalqualitycategory:                 '_signalqualitycategory',
    _signalstrengthcategory:                '_signalstrengthcategory',
    _simslot:                               '_simslot',
    _systemid:                              '_systemid',
    _ta:                                    '_ta',
    _transmissionmode:                      '_transmissionmode',
    _technology:                            '_technology',
    _technologydetail:                      '_technologydetail',
    _ularfcn:                               '_ularfcn',
    _ulfrequency:                           '_ulfrequency'
}; // attrs


// const _dv           = DeviceMsgsModule.values;
const _typeField    = _attrs._technology;

const _hzToMhz      = 1000000;
const _freqDecPlace = 1;
const _unknownStr   = "unknown";


// export const MobileCellSignalCategories =
//     Utils.createEnum([
//         'Invalid',
//         'Min',
//         'Max',
//         'Bad',
//         'Poor',
//         'Average',
//         'Good',
//         'Great'
//     ]
// ); // MobileCellSignalCategories
// // export const _mobileCellSignalCategories = MobileCellSignalCategories;


export class MobileCellSignalCategories
{
    static Map_ = new Map();

    // Create new instances of the same class as static attributes
    static Invalid = new MobileCellSignalCategories("Invalid", 0);
    static Min     = new MobileCellSignalCategories("Min",     98);
    static Max     = new MobileCellSignalCategories("Max",     99);

    static Bad     = new MobileCellSignalCategories("Bad",     1);
    static Poor    = new MobileCellSignalCategories("Poor",    2);
    static Average = new MobileCellSignalCategories("Average", 3);
    static Good    = new MobileCellSignalCategories("Good",    4);
    static Great   = new MobileCellSignalCategories("Great",   5);
  
    constructor(name, id)
    {
        this._id   = id;
        this._name = name;
        MobileCellSignalCategories.Map_.set(name, this);
    }


    get ext()
    {
        const {_id, ...rest} = this;
        return rest;
    }


    get name()
    {
        return this._name;
    }


    static get(name)
    {
        return MobileCellSignalCategories.Map_.get(name);
    }


    static min(c1, c2)
    {
        return (c1 && c2) ? (c1._id <= c2._id ? c1 : c2): c1;
    }
} // MobileCellSignalCategories


// [TBD]
export const MobileCellTypes2 = {
    // Cdma:     _dv.mobilecell_type_cdma,
    // Gsm:      _dv.mobilecell_type_gsm,
    // Lte:      _dv.mobilecell_type_lte,
    // Nr:       _dv.mobilecell_type_nr,
    // Nr_nsa:   _dv.mobilecell_type_nr_nsa,
    // Td_scdma: _dv.mobilecell_type_td_scdma,
    // Umts:     _dv.mobilecell_type_umts,
    // Unknown:  _dv.mobilecell_type_unknown
}; // MobileCellTypes
export const _mobileCellTypesa = MobileCellTypes2;


export class MobileCellCommon extends ElementCommon
{
    // constructor(d, attrs)
    // {
    //     super(d, attrs);
        
    //     //{"cellinstance":897,"serialnumber":"933904000008","technology":"lte",
    //     //"lasttechdata":"2019-03-10t14:02:53z","dlfrequency":"1851700000","band":"3","mcc":"234","mnc":"030",
    //     //"cellid":"1","enbid":"19321","rsrq":"0.00","rsrp":"-86.00","pci":"240",
    //     //"xmlprocesseddate":"2020-07-18t00:07:38z"}

    //     // this._notifications   = {};
 
    //     // this._cellid          = undefined;
    //     // this._frequencyband   = undefined;
    //     // this._dlarfcn         = undefined;
    //     // this._mcc             = undefined;
    //     // this._mnc             = undefined;
    //     // this._technology      = undefined;
    //     // this._ularfcn         = undefined;


    //     // this._status          = undefined;

    //     // this._connecteddate   = undefined;
    //     // this._lastupdateddate = undefined;

    //     // this._merge(d, attrs);
    // }


    static get attrs()
    {
        return _attrs;
    }

    
    static get signalCategories()
    {
        return MobileCellSignalCategories;
    }


    static get Technology()
    {
        return MobileCellType;
        // return _mobileCellTypes;
    }


    static get typeField()
    {
        return _typeField;
    }

    
    static get types()
    {
        return MobileCellType.technology;
        // return _mobileCellTypes;
    }


    // Returns True if passed in object is the same as this, based on selected attributes
    static isSame(mc1, mc2)
    {
        return (mc1 instanceof MobileCellCommon 
            && (Array.isArray(mc2)
                ? mc2.find(c => mc1.isSame(c))

                : (
                    mc2 instanceof MobileCellCommon
                        && mc1.plmn       === mc2.plmn
                        && mc1.technology === mc2.technology
                        && mc1.cellid     ==  mc2.cellid     // [TBD] sometimes string is stored
                )
            )
        ); // return
    }


    //
    // Getters and setters
    //

    // Child objects can override these
    // get areacode()
    // {
    //     console.error("getter areacode() should be overridden");
    //     return 0;
    // }


    get cellcode()
    {
        return this.cellid;
    }

    
    get signalquality()
    {
        console.error("getter signalquality() should be overridden");
        return 0;
    }


    get signalcategory2()
    {
        return MobileCellSignalCategories.min(this.signalstrengthcategory, this.signalqualitycategory);
    }


    get signalqualitycategory2()
    {
        console.error("getter signalqualitycategory() should be overridden");
        return MobileCellSignalCategories.Invalid;
    }


    get signalstrength()
    {
        return this.rssi;
    }

    get signalstrengthcategory2()
    {
        console.error("getter signalstrengthcategory() should be overridden");
        return MobileCellSignalCategories.Invalid;
    }


    // get areacode()
    // {
    //     return this.lac;
    // }


    get areacode()
    {
        return this[MobileCellCommon.attrs._areacode];
    }


    get asu()
    {
        return this[MobileCellCommon.attrs._asu];
    }


    get bandwidth()
    {
        return this[MobileCellCommon.attrs._bandwidth];
    }


    get carrieraggregation()
    {
        return this[MobileCellCommon.attrs._carrieraggregation];
    }
    
    
    get cellid()
    {
        return this[MobileCellCommon.attrs._cellid];
    }


    get dlarfcn()
    {
        return this[MobileCellCommon.attrs._dlarfcn];
    }

    set dlarfcn(d)
    {
        this[MobileCellCommon.attrs._dlarfcn] = d;
    }



    // get dlfrequency() // MHz
    // {
    //     return typeof this.dlfrequencyraw === 'number'
    //         ? this.dlfrequencyraw / 100000
    //         : undefined
    // }


    // get dlfrequencyraw() // Hz
    // {
    //     return this[MobileCellCommon.attrs._dlfrequency];
    // }

    get dlfrequency()
    {
        return this[MobileCellCommon.attrs._dlfrequency];
    }

    set dlfrequency(d)
    {
        this[MobileCellCommon.attrs._dlfrequency] = d;
    }


    get frequencyband()
    {
        return this[MobileCellCommon.attrs._frequencyband];
    }


    get frequencybandname()
    {
        return this[MobileCellCommon.attrs._frequencybandname];
    }


    get frequencynominal()
    {
        return this[MobileCellCommon.attrs._frequencynominal];
    }

    
    get id()
    {
        return this[MobileCellCommon.attrs._id];
    }


    get lac()
    {
        // return this[MobileCellCommon.attrs._lac];
        return this.areacode;
    }


    get localcellid()
    {
        return this[MobileCellCommon.attrs._localcellid];
    }


    get mcc()
    {
        return this[MobileCellCommon.attrs._mcc];
    }


    get mnc()
    {
        return this[MobileCellCommon.attrs._mnc];
    }


    get operator()
    {
        return this[MobileCellCommon.attrs._operator];
    }

    set operator(d)
    {
        this[MobileCellCommon.attrs._operator] = d;
    }

    get operatorstr()
    {
        return (this.operator)
            ? this.operator
            : (this.plmn ? this.plmn : _unknownStr);
    }

    get operatorstr2()
    {
        return (this.operator)
            ? (this.operator
                + (this.plmn ? " (" + this.plmn + ")" : "")
            )
            : (this.plmn ? this.plmn : "");
    }


    get plmn()
    {
        return this[MobileCellCommon.attrs._plmn]
            ? this[MobileCellCommon.attrs._plmn]
            : (this.mcc
                ? ("" + this.mcc + (this.mnc < 10 ? '0' : '') + this.mnc)
                : undefined
            );
    }


    get rssi()
    {
        return this[MobileCellCommon.attrs._rssi];
    }


    get simslot()
    {
        return this[MobileCellCommon.attrs._simslot];
    }


    get signalcategory()
    {
        return this[MobileCellCommon.attrs._signalcategory];
    }

    get signalqualitycategory()
    {
        return this[MobileCellCommon.attrs._signalqualitycategory];
    }

    get signalstrengthcategory()
    {
        return this[MobileCellCommon.attrs._signalstrengthcategory];
    }


    get systemid()
    {
        return this[MobileCellCommon.attrs._systemid];
    }


    get ta()
    {
        return this[MobileCellCommon.attrs._ta];
    }


    get technology()
    {
        return this[MobileCellCommon.attrs._technology];
    }

    set technology(d)
    {
        this[MobileCellCommon.attrs._technology] = MobileCellType.get(d);

        // const s =
        //     (d instanceof MobileCellType)
        //         ? d
        //         : MobileCellType.get(
        //             (d instanceof Object)
        //                 ? Object.assign(new MobileCellType(), d).type
        //                 : d
        //         ); // get

        // if (s instanceof MobileCellType && MobileCellType.Unknown !== s) (this[MobileCellCommon.attrs._technology] = s);
    }

    get technologydetail()
    {
        return this[MobileCellCommon.attrs._technologydetail];
    }

    get technologydisplay()
    {
        return this.technology instanceof MobileCellType ? this.technology.displayTechnology : "";
    }

    // Deprecated
    get technologyDisplayType()
    {
        return this.technology instanceof MobileCellType ? this.technology.displayTechnology : "";
    }

    get technologyType()
    {
        return this.technology instanceof MobileCellType ? this.technology.type : "";
    }

    get technologystr()
    {
        return (this.technology)
            ? this.technologyType
                + (typeof this.technologydetail === "string"
                     && this.technologydetail.toLowerCase() !== this.technologyType
                     && this.technologydetail.toLowerCase() !== _unknownStr
                    ? " " + this.technologydetail.toLowerCase()
                    // ? " - " + this.technologydetail
                    : ""
                )
            // ? this.technology + (this.technologydetail ? " (" + this.technologydetail + ")" : "")
            : _unknownStr;
    }


    get ularfcn()
    {
        return this[MobileCellCommon.attrs._ularfcn];
    }

    set ularfcn(d)
    {
        this[MobileCellCommon.attrs._ularfcn] = d;
    }


    // get ulfrequency() // MHz
    // {
    //     return (typeof this.ulfrequencyraw === 'number')
    //         ? this.ulfrequencyraw / 100000
    //         : undefined
    // }

    // get ulfrequencyraw() // Hz
    // {
    //     return this[MobileCellCommon.attrs._ulfrequency];
    // }


    get ulfrequency()
    {
        return this[MobileCellCommon.attrs._ulfrequency];
    }

    set ulfrequency(d)
    {
        this[MobileCellCommon.attrs._ulfrequency] = d;
    }


    addSignalCategories()
    {
        let cat = MobileCellCommon.attrs._signalqualitycategory;
        if (this[cat]) this._updateCategory(cat);
        else this[cat] = this.signalqualitycategory2;

        cat = MobileCellCommon.attrs._signalstrengthcategory;
        if (this[cat]) this._updateCategory(cat);
        else this[cat] = this.signalstrengthcategory2;
        
        // this[MobileCellCommon.attrs._signalqualitycategory]  = this.signalqualitycategory2;
        // this[MobileCellCommon.attrs._signalstrengthcategory] = this.signalstrengthcategory2;
        this[MobileCellCommon.attrs._signalcategory] = this.signalcategory2;
    }

    _updateCategory(cat)
    {
        return this[cat] = (this[cat] instanceof MobileCellSignalCategories)
            ? this[cat]
            // : MobileCellSignalCategories.get(
                : (this[cat] instanceof Object)
                    ? Object.assign(new MobileCellSignalCategories(), this[cat])
                    : this[cat]
            // ); // MobileCellSignalCategories.get
    }


    isSame(d)
    {
        return MobileCellCommon.isSame(this, d);
    }


    //
    // Protected methods
    //
    _merge(d, attrsE, attrsI)
    {
        super._merge(d, attrsE, {...MobileCellCommon.attrs, ...attrsI});

        // Cause setter to create MobileCellType object
        if (this[MobileCellCommon.attrs._technology]) this.technology = d[MobileCellCommon.attrs._technology]; // must get from original object

        // Convert from Hz to Mhz if required
        if (! isNaN(Number(this.dlfrequency)) && Number(this.dlfrequency) > _hzToMhz) {
            this.dlfrequency = Number((Number(this.dlfrequency) / _hzToMhz).toFixed(_freqDecPlace)).toString();
        }
        if (! isNaN(Number(this.ulfrequency)) && Number(this.ulfrequency) > _hzToMhz) {
           this.ulfrequency = Number((this.ulfrequency / _hzToMhz).toFixed(_freqDecPlace)).toString();
        }

        this.addSignalCategories();

        return this;
    }
}