'use strict'


import { Test } from './index.mjs';


const _attrs = {
    _numRx:       '_numrx',
    _numTx:       '_numtx',
    _numTxActual: '_numtxactual',
    _numErrors:   '_numerrors',
    _numLoss:     '_numloss',
    _pktSize:     '_pktsize',
    _rttAverage:  '_rttaverage',
    _rttMax:      '_rttmax',
    _rttMeanDev:  '_rttmeandev',
    _rttMin:      '_rttmin',
    _waitTime:    '_waittime'
};


export class TestIcmp extends Test
{
    constructor(d, attrs)
    {
        super(d, attrs);
    }


    // Factory method
    static get(d, attrs)
    {
        return (
            d
            ? (d instanceof TestIcmp
               ? d
               : new TestIcmp(d, attrs)
            )
            : undefined
        );
    }


    static get attrs()
    {
        return _attrs;
    }

    
    // Override
    get resultcategory2()
    {
        return Test.resultCategories.Average;
    }


    get numRx()
    {
        return this[_attrs._numRx];
    }


    get numTx()
    {
        return this[_attrs._numTx];
    }


    get numTxActual()
    {
        return this[_attrs._numTxActual];
    }


    get numErrors()
    {
        return this[_attrs._numErrors];
    }


    get numLoss()
    {
        return this[_attrs._numLoss];
    }


    get pktSize()
    {
        return this[_attrs._pktSize];
    }


    get rttAverage()
    {
        return this[_attrs._rttAverage];
    }


    get rttMax()
    {
        return this[_attrs._rttMax];
    }


    get rttMeanDev()
    {
        return this[_attrs._rttMeanDev];
    }


    get rttMin()
    {
        return this[_attrs._rttMin];
    }


    get waitTime()
    {
        return this[_attrs._waitTime];
    }


    _merge(d, attrs)
    {
        super._merge(d, attrs, attrs ? undefined : _attrs);
    }
}