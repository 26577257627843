'use strict'


import { Test } from './index.mjs';


const _attrs = {
    _host:         '_host',
    _responseTime: '_responsetime',
    _responses:    '_responses'
};


export class TestDns extends Test
{
    constructor(d, attrs)
    {
        super(d, attrs);
    }


    // Factory method
    static get(d, attrs)
    {
        return (
            d
            ? (d instanceof TestDns
               ? d
               : new TestDns(d, attrs)
              )
            : undefined
        );
    }


    static get attrs()
    {
        return _attrs;
    }


    // Override
    get resultcategory2()
    {
        return super.resultcategory;
    }

    
    _merge(d, attrs)
    {
        super._merge(d, attrs, attrs ? undefined : _attrs);
    }
}