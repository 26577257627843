'use strict'


import { Test } from './index.mjs';


const _attrs = {
    _direction:        '_direction',
    _duration:         '_duration',
    _durationInterval: '_durationInterval',
    _sizeReq:          '_sizereq',
    _sizeTxfr:         '_sizetxfr',
    _sizeTxfrInterval: '_sizetxfrinterval'
};


export class TestHttp extends Test
{
    constructor(d, attrs)
    {
        super(d, attrs);
    }


    // Factory method
    static get(d, attrs)
    {
        return (
            d
            ? (d instanceof TestHttp
               ? d
               : new TestHttp(d, attrs)
              )
            : undefined
        );
    }


    static get attrs()
    {
        return _attrs;
    }

    
    // Override
    get resultcategory2()
    {
        return Test.resultCategories.Average;
    }


    _merge(d, attrs)
    {
        super._merge(d, attrs, attrs ? undefined : _attrs);
    }
}