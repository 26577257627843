'use strict'


import { ElementType }             from '../ElementType.class.mjs';

import {
    DeviceCommon,
    DeviceTypes
}                                  from './Device.class.mjs';
import { DeviceBeaconCommon }      from './DeviceBeacon.class.mjs';
import { DeviceMobilePhoneCommon } from './DeviceMobilePhone.class.mjs';
import { DevicePowerSocketCommon } from './DevicePowerSocket.class.mjs';
import { DeviceRepeaterCommon }    from './DeviceRepeater.class.mjs';

// import { DeviceMsgsModule }        from '../../messages/index.mjs';


// const _field     = DeviceMsgsModule.attributes.device;
const _field     = ElementType.Device;//_da.device;
const _typeField = DeviceCommon.typeField;


export class DeviceHelper
{
    // Factory method
    static get(d, attrs)
    {
        if (d instanceof DeviceCommon) return d;

        let fn = undefined;
        if (d) {
            const t = DeviceHelper._getType(d);
            switch (t) {
                case DeviceTypes.beacon:
                    fn = DeviceBeaconCommon.get;
                break;

                case DeviceTypes.mobilephone:
                    fn = DeviceMobilePhoneCommon.get;
                break;

                case DeviceTypes.powersocket:
                    fn = DevicePowerSocketCommon.get;
                break;
                
                case DeviceTypes.repeater:
                    fn = DeviceRepeaterCommon.get;
                break;

                default:
                    // console.error("Unknown device type: " + t + ", " + _typeField);
                    // console.error(JSON.stringify(d));
            } // switch
        }

        return (fn instanceof Function)
            ? fn(d, attrs)
            : undefined;
    }


    static get field()
    {
        return _field;
    }


    static get typeField()
    {
        return _typeField;
    }


    //
    // Private methods
    //
    static _getType(d)
    {
        // Use Object(d) to create an object wrapper if there isn't already one to allow 'in' to work
        return (d && _typeField && _typeField in Object(d)) ? Object(d)[_typeField] : undefined;
    }
}