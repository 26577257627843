'use strict'


const _types = {
    connection:     'connection',
    device:         'device',
    devicegroup:    'devicegroup',
    elementmain:    'elementmain',
    geolocation:    'geolocation',
    image:          'image',
    kpi:            'kpi',
    mobilecell:     'mobilecell',
    mobilenetwork:  'mobilenetwork',
    notification:   'notification',
    organisation:   'organisation',
    power:          'power',
    sim:            'sim',
    site:           'site',
    survey:         'survey',
    test:           'test',
    testconnection: 'testconnection',
    testprofile:    'testprofile',
    user:           'user',
    wificell:       'wificell',

    unknown:        'unknown'
}; // _types


export class ElementType
{
    static Connection     = new ElementType(_types.connection);
    static Device         = new ElementType(_types.device);
    static Devicegroup    = new ElementType(_types.devicegroup);
    static Elementmain    = new ElementType(_types.elementmain);
    static Geolocation    = new ElementType(_types.geolocation);
    static Image          = new ElementType(_types.image);
    static Kpi            = new ElementType(_types.kpi);
    static Mobilecell     = new ElementType(_types.mobilecell);
    static Mobilenetwork  = new ElementType(_types.mobilenetwork);
    static Notification   = new ElementType(_types.notification);
    static Organisation   = new ElementType(_types.organisation);
    static Power          = new ElementType(_types.power);
    static Sim            = new ElementType(_types.sim);
    static Site           = new ElementType(_types.site);
    static Survey         = new ElementType(_types.survey);
    static Test           = new ElementType(_types.test);
    static TestConnection = new ElementType(_types.testconnection);
    static TestProfile    = new ElementType(_types.testprofile);
    static User           = new ElementType(_types.user);
    static Wificell       = new ElementType(_types.wificell);
    static Unknown        = new ElementType(_types.unknown);
  

    constructor(d)
    {
       this._type = d;
    }


    // Factory
    static get(d)
    {
        switch(d) {
            case _types.connection:     return ElementType.Connection;
            case _types.device:         return ElementType.Device;
            case _types.devicegroup:    return ElementType.Devicegroup;
            case _types.elementmain:    return ElementType.Elementmain;
            case _types.geolocation:    return ElementType.Geolocation;
            case _types.image:          return ElementType.Image;
            case _types.kpi:            return ElementType.Kpi;
            case _types.mobilecell:     return ElementType.Mobilecell;
            case _types.mobilenetwork:  return ElementType.Mobilenetwork;
            case _types.notification:   return ElementType.Notification;
            case _types.organisation:   return ElementType.Organisation;
            case _types.power:          return ElementType.Power;
            case _types.sim:            return ElementType.Sim;
            case _types.site:           return ElementType.Site;
            case _types.survey:         return ElementType.Survey;
            case _types.test:           return ElementType.Test;
            case _types.testconnection: return ElementType.TestConnection;
            case _types.testprofile:    return ElementType.TestProfile;
            case _types.user:           return ElementType.User;
            case _types.wificell:       return ElementType.Wificell;

            case _types.unknown:
            default:
                return ElementType.Unknown;
        } // switch
    }


    get type()
    {
        return this._type;
    }


    // Override
    toString()
    {
        return "ElementType: " + this.type;
    }
}