'use strict';

import { AttributesHelperCommon } from './Attributes/index.mjs';
import { ElementCommon }          from '../Element.class.mjs';
import { ElementHelperCommon }    from '../Element-helper.class.mjs';
import { ElementMainCommon }      from '../ElementMain.class.mjs';
import { ElementState }           from '../ElementState.class.mjs';
import { ElementStatus }          from '../ElementStatus.class.mjs';
import { ElementType }            from '../ElementType.class.mjs';

import {
    Notification,
    NotificationHelper
}                                 from '../Notification/index.mjs';

import { Dateq }                  from '../../Dateq.class.mjs';


export const DeviceTypes = {
    device:              'device',

    beacon:              'beacon',
    mobilephone:         'mobilephone',
    none:                'none',
    powersocket:         'powersocket',
    repeater:            'repeater'
}; // DeviceTypes
// export const _deviceTypes = DeviceTypes;


export const DeviceActions = {
    survey:              'survey',
    test:                'test'
}; // DeviceActions
// export const _deviceActions = DeviceActions;


const _powerState = {
    off:                 'off',
    on:                  'on',
    status:              'status',
    reset:               'reset',
    unknown:             'unknown'
}; // _powerState


const _attrs = {
    _action:                                '_action',
    _api:                                   '_api',
    _appengineeringmode:                    '_appengineeringmode',
    _appsoftwareversion:                    '_appsoftwareversion',
    _build:                                 '_build',
    _chipset:                               '_chipset',
    _codename:                              '_codename',
    _connectedDate:                         '_connectedDate',
    _connection:                            '_connection',
    [ElementCommon.attrs._description]:     ElementCommon.attrs._description,
    _deviceType:                            '_deviceType',
    [ElementMainCommon.attrs._geolocation]: ElementMainCommon.attrs._geolocation,
    [ElementCommon.attrs._id]:              ElementCommon.attrs._id,
    [ElementCommon.attrs._lastupdateddate]: ElementCommon.attrs._lastupdateddate,
    _load:                                  '_load',
    _logdebugenabled:                       '_logdebugenabled',
    _location:                              '_location',
    _manufacturer:                          '_manufacturer',
    _manufacturerCode:                      '_manufacturerCode',
    _mode:                                  '_mode',
    _model:                                 '_model',
    _modelName:                             '_modelName',
    _monitoringrunning:                     '_monitoringrunning',
    [ElementCommon.attrs._name]:            ElementCommon.attrs._name,
    _notifications:                         '_notifications',
    _powercontrol:                          '_powercontrol',
    _powerstatus:                           '_powerstatus',
    _reconnectedDate:                       '_reconnectedDate',
    _rootpermissionavailable:               '_rootpermissionavailable',
    // _softwareVersion:                       '_softwareVersion',
    _status:                                '_status',
    _surveyrunning:                         '_surveyrunning',
    _systemapp:                             '_systemapp',
    _testrunning:                           '_testrunning',
    _type:                                  '_type',
    _logpath:                               '_logpath'
}; // _attrs



// 2020-05-29
// Can't be static class objects in ES2016, not supported until ES?, need to wait for browser support

const _typeField  = _attrs._type;
const _DeviceType = DeviceTypes.none;


export class DeviceCommon extends ElementMainCommon
{
    // Factory method
    static get(d, attrs)
    {
        return d
            ? (d instanceof DeviceCommon
                ? d
                : new DeviceCommon(d, attrs)
            )
            : undefined;
    }


    // Getters
    static get attrs()
    {
        return _attrs;
    }


    static get Types()
    {
        return DeviceTypes;
    }


    static get typeField()
    {
        return _typeField;
    }


    static get deviceActions()
    {
        return DeviceActions;
    }


    // deprecated
    static get deviceTypes()
    {
        return DeviceTypes;
    }


    static get powerState()
    {
        return _powerState;
    }


    static isType(type)
    {
        return type && Object.values(DeviceCommon.deviceTypes).includes(type);
    }


    get action()
    {
        return this[DeviceCommon.attrs._action];
    }

    set action(d)
    {
        this[DeviceCommon.attrs._action] = d;
    }


    get appEngineeringMode()
    {
        return this[DeviceCommon.attrs._appengineeringmode];
    }


    get appSoftwareVersion()
    {
        return this[DeviceCommon.attrs._appsoftwareversion];
    }


    get connectedDate()
    {
        return (this.connectedDateI instanceof Dateq)
            ? this.connectedDateI.displayStr
            : "";
    }

    get connectedDateI()
    {
        return this[DeviceCommon.attrs._connectedDate];
    }

    set connectedDate(d)
    {
        if (d) this[DeviceCommon.attrs._connectedDate] = Dateq.get(d);
    }


    get connection()
    {
        return this[DeviceCommon.attrs._connection];
    }


    get deviceType()
    {
        return this[DeviceCommon.attrs._deviceType];
    }


    get isOnline()
    {
        return this.statusI === ElementStatus.Online;
    }


    get logdebugenabled()
    {
        // [TBD]
        return this[DeviceCommon.attrs._logdebugenabled];
    }

    set logdebugenabled(d)
    {
        this[DeviceCommon.attrs._logdebugenabled] = !!d;
    }


    get load()
    {
        return this[DeviceCommon.attrs._load];
    }


    get logpath()
    {
        return this[DeviceCommon.attrs._logpath];
    }

    set logpath(d)
    {
        this[DeviceCommon.attrs._logpath] = d;
    }


    get manufacturer()
    {
        return this[DeviceCommon.attrs._manufacturer];
    }


    get manufacturerCode()
    {
        return this[DeviceCommon.attrs._manufacturerCode];
    }


    get model()
    {
        return this[DeviceCommon.attrs._model];
    }


    get modelName()
    {
        return this[DeviceCommon.attrs._modelName];
    }

    
    get monitoringrunning()
    {
        return this[DeviceCommon.attrs._monitoringrunning];
    }

    set monitoringrunning(d)
    {
        this[DeviceCommon.attrs._monitoringrunning] = d;
    }


    get notifications()
    {
        return this[DeviceCommon.attrs._notifications];
    }

    set notifications(d)
    {
        this[DeviceCommon.attrs._notifications] = DeviceCommon._processNotifications(d);
    }


    get powerControl()
    {
        return this[DeviceCommon.attrs._powercontrol];
    }

    set powerControl(d)
    {
        this[DeviceCommon.attrs._powercontrol] = d;
    }


    get powerStatus()
    {
        return this[DeviceCommon.attrs._powerstatus];
    }

    set powerStatus(d)
    {
        this[DeviceCommon.attrs._powerstatus] = d;
    }


    get reconnectedDate()
    {
        return (this.reconnectedDateI instanceof Dateq)
            ? this.reconnectedDateI.displayStr
            : "";
    }

    get reconnectedDateI()
    {
        return this[DeviceCommon.attrs._reconnectedDate];
    }

    set reconnectedDate(d)
    {
        if (d) this[DeviceCommon.attrs._reconnectedDate] =  Dateq.get(d);
    }


    get rootPermission()
    {
        return this[DeviceCommon.attrs._rootpermissionavailable];
    }


    get statusI()
    {
        return this[DeviceCommon.attrs._status];
        // return ElementStatus.get(this[DeviceCommon.attrs._status]);
    }

    get status()
    {
        return (this.statusI instanceof ElementStatus) ? this.statusI.status : "";
        // return ElementStatus.get(this[DeviceCommon.attrs._status]);
    }

    set status(d)
    {
        const s =        
            (d instanceof ElementStatus)
                ? d
                : ElementStatus.get(
                    (d instanceof Object)
                        ? Object.assign(new ElementStatus(), d).status
                        : d
                ); // get

        // Don't do this as part of return
        this[DeviceCommon.attrs._status] =
            (s instanceof ElementStatus && ElementStatus.Unknown !== s)
                ? s
                : undefined;

        this.setState(); // Need to update based on status change
        
        // return this.status;

        // return (this[DeviceCommon.attrs._status] =
        //     (d instanceof ElementStatus)
        //         ? d
                
        //         : ElementStatus.get(
        //             d instanceof Object
        //                 ? Object.assign(new ElementStatus(), d).status
        //                 : d
        //             )  // get
        // );
        // Need to possible change state here

        // Need to have status.none - if object going to web doesn't mention online/offline, don't create any object, esp default offline one
    }

    
    get surveyrunning()
    {
        return this[DeviceCommon.attrs._surveyrunning];
    }

    set surveyrunning(d)
    {
        this[DeviceCommon.attrs._surveyrunning] = d;
    }


    get testrunning()
    {
        return this[DeviceCommon.attrs._testrunning];
    }

    set testrunning(d)
    {
        this[DeviceCommon.attrs._testrunning] = d;
    }


    get systemapp()
    {
        return this[DeviceCommon.attrs._systemapp];
    }


    get type()
    {
        return this[DeviceCommon.attrs._type];
    }


    //
    // Public methods
    //
    processNotification(d)
    {
        if ((d = Notification.get(d)) instanceof Notification) {   // handles if d already Notification
            if (d.isSet) this.notifications[d.id] = d;    // will overwrite if ID is already present
            else         delete this.notifications[d.id]; // remove notification
        } // d

        return d;

        // [TBD] if this happens, need to send this device to the clients - how to notify?
    }


    // Override
    setState()
    {
        super.setState();

        const s = 
            (this.statusI instanceof ElementStatus)
                ? ((ElementStatus.Offline !== this.statusI)
                    ? ElementState.Good
                    : ElementState.Poor
                )

                : ElementState.Unknown;

        if (s instanceof ElementState && ElementState.Unknown !== s) this.state = s;

        return this.state;
    }


    //
    // Protected methods
    //
    _merge(d, attrsE, attrsI)
    {
        // this._setAttrs2(d, attrsE ? attrsE : {..._attrs, ...attrsI}); // [TBD]
        // super._merge(d, attrsE ? attrsE : {..._attrs, ...attrsI});
        super._merge(d, attrsE, {...DeviceCommon.attrs, ...attrsI});

        // App connection
        let o = ElementHelperCommon.get(d, AttributesHelperCommon.fields.connection, undefined, DeviceCommon.attrs._connection, true);
        if (o) this[DeviceCommon.attrs._connection] = o;

        // Notifications
        // o = DeviceCommon._processNotifications(d);
        // if (o) {
        //     if (Array.isArray(o)) {
        //         // Full notification list, replace
        //         this[DeviceCommon.attrs._notifications] = o;
        //     }
        //     else {
        //         if (o instanceof Notification) {
        //             // Individual notification
        //             if (Array.isArray(this[DeviceCommon.attrs._notifications])) {
        //                 let found = false;
        //                 // [TBD]

        //                 // Not found for update, might be new cell for a Sim, add
        //                 if (! found) this[DevicwCommon.attrs._mobilecells].push(o);
        //             }
        //             else {
        //                 this[DeviceCommon.attrs._notifications] = o;
        //             }
        //             console.log(o);
        //         }
        //         else {
        //             console.warn("Unknown object: " + JSON.stringify(o));
        //         }
        //     }
        // }
        // else {
        //     this[DeviceCommon.attrs._notifications] = {};
        // }


        // Set notifications
        this.notifications =
            (d)
                ? AttributesHelperCommon.convertObjectArray(d, DeviceCommon.attrs._notifications, ElementType.Notification)
                : [];

        // Causes setters to run to convert to Dateq
        this.connectedDate   = this[DeviceCommon.attrs._connectedDate];
        this.reconnectedDate = this[DeviceCommon.attrs._reconnectedDate];

        // Cause setter to create ElementStatus object - online/offline
        this.status = this[DeviceCommon.attrs._status];

        // Set ElementState object - good/average/poor
        if (! (this.state instanceof ElementState) || ElementState.Unknown === this.state) this.setState(); // need to re-calc after updating status

        // if (d) {
            // this.cells                   =           this._getAttr(d, 'cells');
            // this.notifications           =           this._getAttr(d, 'notifications');
            // this.operator                =           this._getAttr(d, 'operator');
            
            //this._capabilityPowerControl =           this._getAttr(d, 'capabilityPowerControl');
            // this._description            =           this._getAttr(d, 'systemname');
            
            // this._id                     =           this._getAttr(d, 'id' in d ? 'id'
            //                                                               : ('_id' in d ? '_id'
            //                                                                   : ('serialnumber' in d ? 'serialnumber' : 'serialnum')) );

            // this._location               =           this._getAttr(d, 'sitename');
            // this._manufacturer           =           this._getAttr(d, 'make');
            // this._model                  =           this._getAttr(d, 'model');
            // this._modelName              =           this._getAttr(d, 'modeldisplay');
            // this._powerStatus            =           this._getAttr(d, 'plugStatus');
            // this._status                 =           this._getAttr(d, 'status');

            // this._latitude               =           this._getAttr(d, 'latitude');
            // this._longitude              =           this._getAttr(d, 'longitude');

            // this._connectedDate          = Dateq.get(this._getAttr(d, 'operationaldate'));
            // this._lastUpdatedDate        = Dateq.get(this._getAttr(d, 'lasttechdata'));

            // this._geolocation = AttributesHelperCommon.get(d, DevMessages.attributes.geolocation);
        // }

        return this;
    }
    

    static _processNotification(d)
    {
        return d ? Notification.get(d) : undefined; // handles if d already Notification
    }


    static _processNotifications(d)
    {
        // Convert to objects
        const o = {};
        if (Array.isArray(d)) d.forEach((e) => {
            const m = DeviceCommon._processNotification(e);
            if (m instanceof Notification && m.id) o[m.id] = m;
        }); // forEach

        return o;
    }
}


// // Wrapper code to allow it to be used as both a NodeJS module and an AngularJS factory
// (function(isNode, isAngular)
// {
//     const service = "Devices";
//     const mod     = "qpProMobileApp";


//     function angularInit()
//     {
//         console.log("Initialising " + service + " Service");

//         return DevicesModule();
//     }


//     function nodeInit()
//     {
//         return DevicesModule();
//     }


//     if (isAngular) {
//         // Angular service definition
//         angular
//         .module(mod)
//         .service('qp_' + service + 'Factory', DevicesModule);
//     }
//     else if (isNode) {
//         // NodeJS module definition
//         module.exports = nodeInit();
//     }

// })

// (typeof module !== 'undefined' && module.exports, typeof angular !== 'undefined'); 